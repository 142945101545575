const theme = {
  light: {
    rose: '#e8006b',
    jaune: '#DCFD00',

    // rose: '#a11c71',
    // bleuFonce: '#0e2333',pas utilisé
    beigeFonce: '#b79b79',
    beigeClair: '#c2bda6',
    beigeTresClair: '#e2e0d7',
    beigeMenu: '#736e5f',
    beigeGraph: '#a29887',
    
    bleuBandeauInfo:"#1c2753",
    bleuOverlay:"#1c275399",
    bleuTextes: "#1c2753b3",
    bleuTextes2: "#0a4178",
    
    // bleuHP: '#191a51',
    bleuHP: '#0e1b46',
    bleuClair: '#195aa0',

    // grisHP: "#1c275333",
    grisHP: "#1c2753a6",

    onLineCollab: "#109999cc",
  },
//   dark: {
//     background: '#5d4561',
//     textPrimary: '#fff',
//     textSecondary: '#37364d',
//     buttonPrimary: '#fff',
//     buttonGhost: 'transparent',
//   },
 }

export default theme
