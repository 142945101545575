import React, {useState,useRef,memo} from "react";
import ReactDOM from 'react-dom';
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import PopupBandeauInfoHP from "../Popups/PopupBandeauInfo";
import axios from "axios";
import DOMPurify from 'isomorphic-dompurify';
import { Swiper, SwiperSlide } from 'swiper/react';
import { register } from 'swiper/element/bundle';
import { Navigation, Autoplay } from 'swiper/modules';
import { AiOutlineClose } from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { GoArrowLeft,GoArrowRight  } from "react-icons/go";
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const StyledSwiper = styled(Swiper)`
max-width: 100%;
width: 100%;
cursor: pointer;
transition: all 0.5s ease-in-out;
&>div {
  max-width: 100%;
}
@media (max-width: 768px) {
  flex-direction: column;
  padding: ${props => props.paddingMob};
}
`;

const StyledSwiperSlide = styled(SwiperSlide)`
height: auto;
display: flex;
max-width: 100%;
align-items: center;
justify-content: center;
 &> div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 55px; 
  @media (max-width: 820px) {
    flex-direction: column;
    padding: 20px 40px;
  }
}
`

const BandeauWrapper = styled.div`
width: 100%;
color: #fff;
cursor: pointer;
display: flex;
justify-content: space-between;
align-items: center;
transition: all 0.5s ease-in-out;
@media (max-width: 768px) {
  flex-direction: column;
}
`;

const Info = styled.div`
margin: 10px 20px;
margin-left: 20%;
@media (max-width: 768px) {
  margin: 0 10px;
}
`
const Titre = styled.div`
font-weight: 600;
`

const Txt = styled.div`
color: rgb(255, 255, 255); 
text-align: left;

`;

const Buttons = styled.div`
margin: 10px;
display: flex;
flex-direction: column;
justify-content: space-between;
@media (max-width: 768px) {
width: 100%;
flex-direction: row;
justify-content: space-between;
gap: 5px;
margin: 0;
padding: 10px 0;
}
@media (max-width: 768px) {
  padding: 20px 0 40px 0;
  }
  @media (max-width: 320px) {
    flex-wrap: wrap;
  }
`

const ButtonTxt2 = styled.a`
display: flex;
align-items: center;
padding: 5px;
white-space: nowrap;
border: none !important;
&:hover {
    color: ${props => props.hover} !important;
  }
 
`

const ButtonTxt = styled.div`
display: flex;
align-items: center;
padding: 5px;
&:hover {
  color: ${props => props.hover} !important;
}
@media (max-width: 768px) {
  }
`

const Close = styled.div`
color: #fff;
border : 2px solid #fff;
padding: 2px;
border-radius: 50%;
width: 35px;
height: 35px;
margin-right: 5px;
cursor: pointer;
position: static;
&>svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform-origin: center;
}
&:hover {
    border-color: ${props => props.theme.jaune};
    color: ${props => props.theme.jaune};
  }
`;

const Arrows = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 8px;
position: absolute;
top: 40%;
left: 50px;
transform: translateZ(-50%);
z-index: 1;
@media (max-width: 768px) {
  width: 100%;
  top: 83%;
  left: 50%;
  transform: translateX(-50%);
  gap: 30px;
}
@media (max-width: 400px) {
  top: 86%
  }
`

const Arrow = styled.div`
color: #fff;
border : 2px solid #fff;
border-radius: 50%;
width: 35px;
height: 35px;
cursor: pointer;
position: static;
margin-top: 0 !important;
&.swiper-button-next {
  &::after {
    display: none;
  }
}
&.swiper-button-prev {
  &::after {
    display: none;
  }
}
&:hover {
    border-color: ${props => props.theme.jaune};
    color: ${props => props.theme.jaune};
  }
`

const BandeauInfoHP = memo(( {dataBannieres, visibleBanners,  handleCloseSlide}) => {
const swiperRef = useRef(null);
const [activeIndex, setActiveIndex] = useState(0);
const [opacity, setOpacity] = useState(1);
const [maxHeight, setMaxHeight] = useState('650px');

const handleClose = () => {
  setOpacity(0);
  setMaxHeight('0px');
  localStorage.setItem('hideBandeauInfoHP', 'true');
};




const [banniereAction, setBanniereAction] = useState({
  Action: "",
  ID : ""
});

const sendInfo = (info,id) => {
  if (localStorage.getItem('hideBandeauInfoHP') !== 'true') {
  setBanniereAction({ Action: info, ID: id });

  axios.post(`/users/banniereaction`, {Action:banniereAction.Action,ID:banniereAction.ID} )
    .then((response) => {
      setBanniereAction({Action: "", ID: ""})
      console.log(response);
    })
    .catch((err) => {
      console.log(err);
    })
  }
}

const handeCloseSlideChild = (i) => {
  handleCloseSlide(i);

}
if (localStorage.getItem('hideBandeauInfoHP') !== 'true') {
    return (
      <>
        <BandeauWrapper style={{opacity: opacity,maxHeight: maxHeight }}>
            <StyledSwiper 
              ref={swiperRef}
              onSlideChange={(swiper) => {
                const item = swiper?.slides[swiper?.activeIndex];
                sendInfo("BanniereAffichage", item?.id);
              }}
              activeIndex={activeIndex} 
              grabCursor={true}
              loop={true}
              // autoplay={{pauseOnMouseEnter: true, delay: 30000, disableOnInteraction:false}}
              slideActiveClass="active"
              slideNextClass="next"
              slidePrevClass="prev"
              slidesPerView= {1}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              }}
              modules={[Navigation, Autoplay]}
            >
            
              {
                dataBannieres && dataBannieres?.map((item, i) => {
                  return (
                    <StyledSwiperSlide key={item?.ID}  id={item?.ID} style={{backgroundColor: item?.CouleurFond, opacity:visibleBanners && visibleBanners[i] ? 1 : 0 }}>
                      <div>
                      <Info>
                        <Titre style={{color: item?.CouleurTitre}}>{item?.title}</Titre>
                        <Txt style={{color: item?.CouleurTexte}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.TexteCourt) }}></Txt>
                      </Info>
                      <Buttons>
                        <ButtonTxt2 onClick={() => sendInfo("BanniereLitPlus", item?.ID)} href={item?.slug} style={{color: item?.CouleurLien}} hover={item?.CouleurLienSurvol} target="_blank"><FaChevronRight/>{item?.BoutonTxt}</ButtonTxt2>
                        <ButtonTxt id={item?.ID} style={{color: item?.CouleurFermer}} hover={item?.CouleurFermerSurvol} onClick={() => {
                        handleCloseSlide(i);
                        sendInfo("BanniereFerme", item?.ID);
                      }}><FaTimes/>Fermer</ButtonTxt>
                      </Buttons>
                      </div>
                    </StyledSwiperSlide>
                  );
                })
              }
              
              <Arrows>
                <Close className=""><AiOutlineClose onClick={handleClose}/></Close>
                <Arrow className="swiper-button-next"><GoArrowLeft /></Arrow>
                <Arrow className="swiper-button-prev"><GoArrowRight /></Arrow>
              </Arrows>  
            </StyledSwiper>
            
        </BandeauWrapper> 
      </>
    );
  } else {
    return null;
  }
});
export default BandeauInfoHP;