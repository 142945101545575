import React, {useState, useRef, useEffect} from "react";
import styled from 'styled-components';
import  {Col, Row,} from "reactstrap";
import BoutonJaune from "../DesignSystem/BoutonJaune";
import {SelectFilters,TextareaStyled} from "../DesignSystem/Commons/Elements";
import {Legal} from "../DesignSystem/Commons/Containers";
import { FormConnect,InputStyled } from "../DesignSystem/Commons/Forms";
import BoutonJauneInverseHP from "../DesignSystem/BoutonJauneInverse";
import { ButFermer } from "../DesignSystem/PopUp";
import {IoCloseCircleOutline} from "react-icons/io5";
import {RowStyledForm, ButsForm} from "../DesignSystem/Commons/Containers";
import BoutonRoseHP from "../DesignSystem/BoutonRose";


export function FormOubliHP({onClick,title, className}) {
    const formRef = useRef();
    const [open, setOpen] = useState(false);


    useEffect(() => {
        function handleClickOutside(event) {
          if (formRef.current && !formRef.current.contains(event.target)) {
            onClick();
          }
        }
  
        document.addEventListener('mouseup', handleClickOutside);

        return () => {
          document.removeEventListener('mouseup', handleClickOutside);
        };
      }, [onClick]);

        return(
        <>
        <RowStyledForm className={className} ref={formRef}>
        <ButFermer onClick={onClick}><IoCloseCircleOutline/></ButFermer>
             <div>
                <FormConnect className="maxWidth">
                    {
                        className === "mdp" ?
                        <InputStyled id=""
                        name="nom"
                        placeholder="Identifiant"
                        type="text"/>
                        :""
                    }
                    <InputStyled id=""
                        name="nom"
                        placeholder="Nom (obligatoire)"
                        type="text"/>
                    <InputStyled id=""
                        name="prenom"
                        placeholder="Prénom (obligatoire)"
                        type="text"/>
                    <InputStyled id=""
                        name="email"
                        placeholder="Email"
                        type="Email"/>
                </FormConnect>

                 <ButsForm>
                    <BoutonJauneInverseHP className={"annuler"} libellé="Annuler" onClick={onClick}></BoutonJauneInverseHP>
                    <BoutonJaune className={"check"} libellé="Valider"></BoutonJaune>
                </ButsForm>
             </div>
        </RowStyledForm>
        </>
    )
}

export default FormOubliHP;
