import React from "react";
import { useState, CSSProperties } from "react";
import ReactDOM from 'react-dom';
import ReactHtmlParser from 'react-html-parser';
import {PopUpContainer, PopUp,PopUpTitle, Wrap, ButFermer,ButtonPop} from "../DesignSystem/PopUp";
import {IoCloseCircleOutline} from "react-icons/io5";
import {BsArrowRightCircle} from "react-icons/bs";


function PopErrorHP({titre, message, onClick, className}) {


  return (
    <>
     {ReactDOM.createPortal(
                <PopUpContainer>
                  <PopUp className={className}>
                    {/* {className !== "hideBtn" ? <ButFermer onClick={onClick}><IoCloseCircleOutline/></ButFermer> : null} */}
                      <PopUpTitle>{titre}</PopUpTitle>
                      <Wrap>
                         {ReactHtmlParser(message)}
                         <ButtonPop className="center" onClick={onClick}><IoCloseCircleOutline/>Fermer</ButtonPop>
                      </Wrap>
                    </PopUp>
                </PopUpContainer>,
                document.getElementById('portal')
              )} 
 
    
    </>
  );
}

export default PopErrorHP;