import React, { useState, useEffect, useRef, memo } from "react";
import ReactDOM from 'react-dom';
import styled from "styled-components";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { Drawer, Layout, Menu } from "antd";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "gatsby";
import breakpoints from "../../constants/breakpoints";
import FormSearch from "../Forms/FormSearch";
import { FormConnect } from "../DesignSystem/Commons/Forms";
import FormConnectionSimpleHP from "../Forms/FormConnexionSimple";
import DrawerMenu from "./DrawerMenuMob";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";


const { Sider } = Layout;
const { SubMenu } = Menu;

const StyledMenu = styled(Menu)`
`;


const StyledDrawer = styled(Drawer)`
  & > .ant-drawer-body {
    padding: 10px 0 !important;
  }
  & > .ant-drawer-body > aside {
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
    & > div > ul > li > div > i {
      display: none;
    }
  }
`;

const StyledSubMenu = styled(SubMenu)`
font: 700 16px Quicksand, sans-serif !important;
color: #735f66 !important;

  width: 100%;
  &>div>
  & > div.ant-menu-submenu-title {
    white-space: wrap !important;
    padding: 10px 10px 10px 25px !important;
    height: unset !important;
    line-height: 25px !important;
  }
  &.niv1 > .ant-menu-submenu-title {
    color: #735f66 !important;
    text-transform: uppercase;
  }
  &.niv2 > .ant-menu-submenu-title {
    color: ${(props) => props.theme.bleuClair};
    text-transform: uppercase;
    &> i {
      display: none;
    }
  }
  &.niv1.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: ${(props) => props.theme.rose};
    text-transform: uppercase;
  }
  &.niv2.ant-menu-submenu-selected > .ant-menu-submenu-title {
    /* text-transform: uppercase;
    border-top: 2px solid ${(props) => props.theme.jaune};
    background: ${(props) => props.theme.bleuHP}; 
    color: #fff; */
  }
  &.niv2.ant-menu-submenu-selected
    > ul
    > .niv3.ant-menu-item.ant-menu-item-selected
    > .ant-menu-title-content {
    color: ${(props) => props.theme.bleuHP} !important;
  }

  &.niv2.ant-menu-submenu > ul > .niv3.ant-menu-item > .ant-menu-title-content > a {
    color: ${(props) => props.theme.bleuHP} !important;
    font: 700 16px Quicksand, sans-serif !important;
    border: none !important;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  white-space: wrap !important;
  height: unset !important;
  line-height: 25px !important;
  padding: 10px 10px 10px 60px !important;
  &.plus {
    padding: 10px 10px 10px 30px !important;
  }
  &>.ant-menu-title-content>div > a {
    border: none !important;
    color: #735f66 !important;
    font: 700 16px Quicksand, sans-serif !important;
    text-transform: uppercase;
  }
`;

const StyledNavLink = styled(NavLink)`
  position: relative;
  cursor: pointer;
  z-index: 1;
  color: #735f66;
  text-decoration: none;
  display: inline-block;
  padding: ${(props) => props.padding || "0"};
  margin: ${(props) => props.margin || "0"};
  font: 400 21px Quicksand, sans-serif;
  font-weight: ${(props) => props.fonWeight || "600"};
  /* color:${(props) => props.color || "#fff"}; */
  text-transform: ${(props) => props.transform || "uppercase"};
  text-align: ${(props) => props.align || "left"};

  /* &.test {
    &::before, ::after {
        height: 11px;
        top:50%;
        background: ${(props) => props.theme.rose};
    }
    &:hover::before {
    background: ${(props) => props.theme.rose};}
} */
  &:hover {
    color: ${(props) => props.colorOnHover || "#735f66"};
  }
  &.active::before {
    background: #dcfd00;
    width: 100%;
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  &::before {
    background: ${(props) => (props.isShown ? "#dcfd00" : "initial")};
    width: ${(props) => (props.isShown ? "100%" : "initial")};
    transition: ${(props) =>
    props.isShown ? "width .5s cubic-bezier(.22, .61, .36, 1)" : "initial"};
  }
  &::after,
  ::before {
    content: "";
    position: absolute;
    width: 0%;
    height: ${(props) => props.heightOfLine || "11px"};
    top: 48%;
    background: #dcfd00;
    z-index: -1;
  }
  &::after {
    right: 0px;
    background: #dcfd00;
    transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
  }
  &::before {
    ${(props) =>
    props.isActive
      ? `
        position: absolute;
        content: '';
        display: block;
        width:91%;
        background: #dcfd00;
        height: 11px;
        top: 50%;
        left: 0px;
        z-index: -1;
        `
      : `
        position: absolute;
        content: '';
        display: block;
        width: 0%;
        background: #dcfd00;
        height: 11px;
        top: 50%;
        left: 0px;
        z-index: -1;
        `}
  }

  &:hover::before {
    background: #dcfd00;
    width: 100%;
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  }
`;

const StyledDiv = styled(Navbar)`
  background: #fff;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  & > div {
      padding: 0 !important;
  }

  @media (max-width: 768px) {
    padding: 10px;
    justify-content: center;
  }
  justify-content: center;
`;



const StyledSVG = styled.svg`
  transition: fill 0.1s ease-in-out;
  transform: rotate(${(props) => props.rotate + 90}deg);
  ${StyledNavLink}:hover & {
    fill: ${(props) => props.theme.rose};
  }
`;

const StyledDivLienWrapper = styled(Nav)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
const StyledDivLienWrapperNavBleu = styled(Nav)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0px;
  & > svg {
    fill: #fff;
  }
`;

const NavbarStyled = styled(Navbar)`
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  max-height: 75px;
  background-color: ${(props) => props.theme.bleuHP};
  padding: 0 100px;
  &.expanded {
    max-height: 600px;
    transition: max-height 0.5s ease-in-out;
  }
  @media (max-width: 768px) {
    padding: 20px 0 10px;
  }
  & > div {
    @media (max-width: 768px) {
      display: flex;
      justify-content: center !important;
      align-items: center;
      padding: 0;
    }
  }
`;
const LienNavBleu = styled.button`
  color: #fff !important;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: none !important;
  box-sizing: border-box;
  font-size: 17px;
  gap: 10px;
  &:hover {
    color: #dcfd00 !important;
    text-decoration: none;
  }
  @media (max-width: 768px) {
    & > span {
      display: none;
    }
  }

  & > svg {
    margin: 0 5px;
    rotate: 90;
  }
  &.bold {
    font-weight: 800;
    letter-spacing: 0.1rem;
  }
  &.margin {
    margin-left: -37px;
    @media (max-width: 992px) {
      margin-left: 0;
    }
    &:hover {
      color: #dcfd00;
      text-decoration: none;
    }
  }
`;

const StyledDivLien = styled(NavItem)`
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  display: flex;
  position: absolute;
  top: 118px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  left: 0;
  right: 0;
  z-index: 999;
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
  align-items: baseline;

  @media (max-width: ${breakpoints.xl}) {
    position: relative;
    offset-position: 0px -50px;
    top: 0;
    flex-direction: column;
    gap: 20px;
    display: contents;
  }
`;

const StyledNavBarBrandLG = styled(NavbarBrand)`
  margin: 0;
  display: none;
  border-bottom: none !important;
  @media (min-width: ${breakpoints.xl}) {
    display: block;
    padding: 10px 0;
  }
`;

const StyledNavBarBrandXS = styled(NavbarBrand)`
  padding-left: 50px;
  padding-right: 50px;
  border-bottom: none !important;
  @media (min-width: ${breakpoints.xl}) {
    display: none;
  }
`;

const StyledImg = styled.img`
  //box-sizing: border-box;
  //flex-shrink: 0;
  /* width: 104px;
  height: 72px; */
  //position: relative;
  //padding: 0px 92px 0px 92px;
`;

const StyledNav = styled(Nav)`
  box-sizing: border-box;
  display: flex;
  padding: 0px 100px;
  //gap: 50px;
  //align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: 100%;
`;
const StyledNavbarToggler = styled(NavbarToggler)`
  border: none;
  display: none;
`;

const StyledNavbarTogglerResp = styled(StyledNavbarToggler)`
  position: static;
  top: 175px;
  right: 10px;
  z-index: 999;
  background-color: #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  border: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  &.hiddenBandeau {
    top: 65px;
  }
  @media screen and (min-width: 1200px) {
    position: static;
    bottom: auto;
    right: auto;
  } 
  @media screen and (max-width: 870px) {
    top: 200px;
  }
  @media screen and (max-width: 768px) {
    top: 250px;
    &.hiddenBandeau {
    top: 90px;
    }
  }
  @media screen and (max-width: 610px) {
    top: 270px;
  } 
  @media screen and (max-width: 460px) {
    top: 300px;
  }
  @media screen and (max-width: 410px) {
    top: 320px;
    &.hiddenBandeau {
    top: 110px;
    }
  }
`;

const StyledDiv_2 = styled(NavItem)`
  background-color: #ffff;
  box-sizing: border-box;
  padding: 17px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  & > li:last-child {
    margin-bottom: 30px !important;
  }
`;

const StyledDiv_4 = styled(Nav)`
  overflow: hidden;
  display: block;
  /* width: 100%; */

  z-index: 1;
  /* color: ${(props) => props.theme.bleuHP}; */
  text-align: center;
  font: 600 21px Quicksand, sans-serif;
  text-transform: uppercase;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  padding: 10px 0;
  &:hover {
    color: ${(props) => props.theme.rose};
    /* text-decoration: none; */
  }

  &::before {
    background: ${(props) => (props.isShown ? "#dcfd00" : "initial")};
    width: ${(props) => (props.isShown ? "100%" : "initial")};
    transition: ${(props) =>
    props.isShown ? "width .5s cubic-bezier(.22, .61, .36, 1)" : "initial"};
  }
  &::after,
  ::before {
    content: "";
    position: absolute;
    width: 0%;
    height: ${(props) => props.heightOfLine || "11px"};
    top: 48%;
    background: #dcfd00;
    z-index: -1;
  }
  &::after {
    right: 0px;
    background: #dcfd00;
    transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
  }
  &::before {
    ${(props) =>
    props.isActive
      ? `
        position: absolute;
        content: '';
        display: block;
        width:100%;
        background: #dcfd00;
        height: 11px;
        top: 50%;
        left: 0px;
        z-index: -1;
        `
      : `
        position: absolute;
        content: '';
        display: block;
        width: 0%;
        background: #dcfd00;
        height: 11px;
        top: 50%;
        left: 0px;
        z-index: -1;
        `}
  }

  &:hover::before {
    background: #dcfd00;
    width: 100%;
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
    /* width: ${(props) => props.widthOfLine || "117%"}; */
  }
`;

const DropdownItemSous = styled(Link)`
  color: ${(props) => props.theme.gris};
  /* text-align: center; */
  width: 100%;
  border-bottom: none !important;
  font: 600 18px Quicksand, sans-serif;
  &:hover {
    color: ${(props) => props.theme.rose};
    text-decoration: none;
  }

  &:first-of-type {
    margin-top: 30px !important;
  }
`;

const DivSous = styled.div`
    position: absolute;
    top: 80px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    gap: 20px;
    background: #fff;
    width: 100%;
    
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;
    &>a {
      text-align: center;
      &:last-child {
        padding-bottom: 30px;
      }
    }
    `


const NavBar = memo(({ classNameB, showBtnDoc }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
  allMenuHaut {
    edges {
      node {
        id
        ID
        Type
        Version
        Lib
        Titre
        slug
        title
        Tri
        Bulle
        URL
        Famille
        Droit {
          Etat
          TypeCts
          AuthentLevel
        }
        MenuBleu {
          ID
          Type
          Version
          Lib
          Titre
          slug
          title
          Tri
          Bulle
          Droit {
            Etat
            TypeCts
            AuthentLevel
          }
          MenuSous {
            ID
            Type
            Version
            Lib
            Titre
            slug
            title
            Tri
            Bulle
            Droit {
              Etat
              TypeCts
              AuthentLevel
            }
          }
        }
      }
    }
  }

    }`);
  const dataMenuHaut = data.allMenuHaut.edges.map((item) => item.node);
  const items2 = dataMenuHaut?.map((menu, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      // icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: menu.MenuBleu.map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  });
  // console.log('reactdatamenuhaut',dataMenuHaut);
  const navbarRef = useRef(null);
  const navbarRef2 = useRef(null);

  let menuHautData = [...dataMenuHaut];
  menuHautData.splice(6, 1);
  const newItem = { Lib: "SCPI fiscales", slug: "/scpi-residentielles/?58" };

  if (!menuHautData[2]?.MenuBleu[3]?.MenuSous.some(item => item.Lib === newItem.Lib && item.slug === newItem.slug)) {
    menuHautData[2]?.MenuBleu[3]?.MenuSous.splice(5, 0, newItem);
  }



  const [user, setUser] = useState(null);
  const [formQuestion, setFormQuestion] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleNav = () => setIsOpen(!isOpen);
  const [activeItem, setActiveItem] = useState(null);
  const [activeItemSous, setActiveItemSous] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [isShown, setIsShown] = useState(false);
  const [isShownSous, setIsShownSous] = useState(false);
  const [dropdownOpenSous, setDropdownOpenSous] = useState({});
  const [navTogglerResp, setNavTogglerResp] = useState(false);
  const [lib, setLib] = useState("Accès client");
  const [classUser, setClassUser] = useState("closed");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const isLogged = useSelector((state) => state.firstSlice.isLogged);
  const [showFormSearch, setShowFormSearch] = useState(false);
  const [showFormConnexion, setShowFormConnexion] = useState(false);
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  ;


  useEffect(() => {

    if (localStorage.getItem("user")) {
      // setLib("Me déconnecter");
      setClassUser("open");
    } else {
      setLib("Accès client");
      setClassUser("closed");
      if (showFormConnexion) {
        // setLib("Fermer");
      }
    }

  }, [showFormConnexion]);


  useEffect(() => {

    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsOpen(false);
        setDropdownOpen({});
        setDropdownOpenSous({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleIsOpen = (id) => {
    setActiveItem(id);
    setDropdownOpen((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {});
      newState[id] = !prevState[id];
      return newState;
    });

    // Set all dropdownOpenSous to false
    setDropdownOpenSous((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {});
      return newState;
    });
  };

  const handleIsOpenSous = (id) => {
    setActiveItemSous(id);
    setDropdownOpenSous((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {});
      newState[id] = !prevState[id];
      return newState;
    });
  };

  const handleLogin = (isSuccessful) => {
    setIsNavbarExpanded(!isSuccessful);
    setShowFormConnexion(!isSuccessful);
    window.scrollTo(0, 0);
    // if(showFormConnexion){
    //   setLib("Fermer");
    // } else {
    //   setLib("Accès client");
    // }
    // setIsLoggedIn(isSuccessful);
    // if (!user) {
    //   setLib("Accès client");
    // } else {
    //   setLib("Me déconnecter");
    // }
  };



  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    const isNiv1 = latestOpenKey && latestOpenKey.startsWith('sub');

    // If the latest opened submenu is a niv1 submenu, only keep it open
    // Otherwise, keep all the submenus open
    setOpenKeys(isNiv1 ? [latestOpenKey] : keys);
  };

  const onCloseRecherche = () => {
    setShowFormSearch(false);
    setIsNavbarExpanded(false);
  };

  const toggleFormSearch = (event) => {
    event.preventDefault();
    setShowFormSearch((prevShowFormSearch) => {
      // If we're closing the form, and the other form is not open, collapse the navbar
      if (prevShowFormSearch && !showFormConnexion) {
        setIsNavbarExpanded(false);
      }
      // If we're opening the form, expand the navbar
      else if (!prevShowFormSearch) {
        setIsNavbarExpanded(true);
        window.scrollTo(0, 0);
      }
      return !prevShowFormSearch;
    });
    if (showFormConnexion) {
      setShowFormConnexion(false);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      // setShowFormConnexion(false);
      if (window.scrollY > 100) {
        setNavTogglerResp(true);
      } else {
        setNavTogglerResp(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef2.current && !navbarRef2.current.contains(event.target)) {
        setShowFormConnexion(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeNav = () => {
    setIsOpen(false);
    setDropdownOpen({});
    setDropdownOpenSous({});
  };
  const toggleFormConnexion = () => {
    setShowFormConnexion((prevShowFormConnexion) => {
      if (prevShowFormConnexion && !showFormSearch) {
        setIsNavbarExpanded(false);
        if (user) {
          // setLib("Me déconnecter");
          setClassUser("open");
        } else {
          setLib("Accès client");
          setClassUser("closed");
        }
      }
      // If we're opening the form, expand the navbar
      else if (!prevShowFormConnexion) {
        setIsNavbarExpanded(true);
        window.scrollTo(0, 0);
        if (user) {
          // setLib("Fermer");
        }
      }
      return !prevShowFormConnexion;
    });
    if (showFormSearch) {
      setShowFormSearch(false);
    }
  };
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setOpenKeys([]);
  };

  return (
    <div ref={navbarRef} id="menu">
      {/* <Transition 
  in={isNavbarExpanded} 
  timeout={500}
>
  {state => ( */}
      <div ref={navbarRef2}>
        <NavbarStyled
          className={`my-0 ${isNavbarExpanded ? "expanded" : ""}`}
          style={
            {
              // ...transitionStyles[state],
              // transition: 'max-height 0.5s ease-in-out',
              // overflow: 'hidden'
            }
          }
        >
          <StyledDivLienWrapperNavBleu>
            <LienNavBleu onClick={toggleFormConnexion}>
              <span>{lib}</span>
              {classUser === "open" ? <FaLockOpen /> : <FaLock />}
              {/* <FaLock />
              <FaLockOpen/> */}
            </LienNavBleu>
          </StyledDivLienWrapperNavBleu>

          <StyledDivLienWrapperNavBleu>
            <LienNavBleu className="bold margin" href="tel:33153430210">
              <FaPhone />
              01 53 43 02 10
            </LienNavBleu>
          </StyledDivLienWrapperNavBleu>
          <StyledDivLienWrapperNavBleu>
            <LienNavBleu onClick={toggleFormSearch}>
              <span>Recherche</span>
              <FaSearch />
            </LienNavBleu>
          </StyledDivLienWrapperNavBleu>
          {showFormSearch ? (
            <FormSearch className={"nav"} showFormSearch={showFormSearch} onCloseRecherche={onCloseRecherche} />
          ) : (
            ""
          )}
          {showFormConnexion ? (
            <FormConnectionSimpleHP
              className={"nav"}
              showFormSearch={showFormConnexion}
              onLogin={handleLogin}
              user={user}
            />
          ) : (
            ""
          )}
        </NavbarStyled>
      </div>
      {/* )}
   </Transition> */}

      <StyledDiv expand="xl" container="fluid" className="g-0">
        <StyledNavBarBrandXS href="/">
          <StaticImage src="../../images/LogoHP.webp" alt="Logo Haussmann Patrimoine" placeholder="none" backgroundColor="#fff" loading="lazy" />
        </StyledNavBarBrandXS>

        <StyledNavbarTogglerResp onClick={showDrawer} className={classNameB} />
        {/* { navTogglerResp && <StyledNavbarTogglerResp onClick={showDrawer} />} */}
        <Collapse isOpen={isOpen} navbar>
          <StyledNav className="me-auto" navbar>
            {menuHautData &&
              menuHautData
                .sort((a, b) => (a.Tri < b.Tri ? -1 : 1))
                .map(
                  (menu, i) => (
                    (
                      <>
                        {i === 3 && (
                          <StyledNavBarBrandLG href="/" key={i}>
                            <StaticImage src="../../images/LogoHP.webp" alt="Logo Haussmann Patrimoine" placeholder="none" backgroundColor="#fff" loading="lazy" />
                          </StyledNavBarBrandLG>
                        )}

                        <StyledDivLienWrapper key={menu.ID}>
                          <StyledNavLink
                            key={menu.ID}
                            isShown={dropdownOpen[menu.ID] === true}
                            onClick={() => handleIsOpen(menu.ID)}
                            isActive={activeItem === menu.ID}
                            nav
                            tag={"div"}
                          >
                            {menu?.Lib}
                            {/* <StyledSVG
                              width="12"
                              height="6"
                              viewBox="0 0 12 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/StyledSVG"
                            >
                              <path
                                d="M0 0L6 6L12 0"
                                stroke="white"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </StyledSVG> */}
                          </StyledNavLink>
                        </StyledDivLienWrapper>
                        {dropdownOpen[menu.ID] && (
                          <StyledDivLien show={dropdownOpen[menu.ID] === true} className={menu?.MenuBleu.length}>
                            {menu?.MenuBleu &&
                              menu?.MenuBleu.map((bleu, i) => (
                                <>
                                  <StyledDiv_2 key={bleu.ID} style={{ width: menu?.MenuBleu.length < 4 ? '25%' : `calc(100% / ${menu?.MenuBleu.length})` }}>
                                    <StyledDiv_4
                                      isActive={activeItemSous === bleu.ID}
                                      fluid
                                      onClick={() => {
                                        handleIsOpenSous(bleu.ID);
                                        setActiveItem(menu.ID);
                                      }}
                                      tag="div"
                                    >
                                      {bleu?.Lib}
                                    </StyledDiv_4>

                                    {/* {bleu?.MenuSous &&
                                    bleu?.MenuSous.map((sous) => (
                                      <>
                                        {dropdownOpenSous[bleu.ID] && (
                                          <DropdownItemSous
                                            onClick={() => {
                                              closeNav();
                                            }}
                                            showsous={dropdownOpenSous[sous.ID]}
                                            key={sous.ID}
                                            to={sous?.slug}
                                          >
                                            {sous?.Lib}
                                          </DropdownItemSous>
                                        )}
                                      </>
                                    ))} */}
                                  </StyledDiv_2>
                                  <DivSous> {bleu?.MenuSous &&
                                    bleu?.MenuSous.map((sous) => (
                                      <>
                                        {dropdownOpenSous[bleu.ID] && (
                                          <DropdownItemSous
                                            onClick={() => {
                                              closeNav();
                                            }}
                                            showsous={dropdownOpenSous[sous.ID]}
                                            key={sous.ID}
                                            to={sous?.slug}
                                          >
                                            {sous?.Lib}
                                          </DropdownItemSous>
                                        )}
                                      </>
                                    ))}</DivSous>
                                </>
                              ))}
                          </StyledDivLien>
                        )}
                      </>
                    )
                  )
                )}
          </StyledNav>
        </Collapse>
        <>
          {/* <StyledDrawer
          
            placement="left"
            closable={true}
            onClose={onClose}
            open={visible}
            width={400}
            extra={<StyledImg src={Logo} style={{ width: " 120px" }} />}
          >
       
            <Sider width={400}>
              <StyledMenu
                mode="inline"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                // defaultSelectedKeys={["1"]}
                // defaultOpenKeys={["sub1"]}
                style={{ height: "100%", borderRight: 0 }}
              > */}
          {/* <Menu.Item key="1" onClick={toggleFormConnexion} style={{ color: 'red' }}>
            <span>Accès client</span>
            <FaLock />
          </Menu.Item>
          <Menu.Item key="2" className="bold margin" style={{ color: 'green' }}>
            <a href="tel:33153430210">
              <FaPhone />01 53 43 02 10
            </a>
          </Menu.Item>
          <Menu.Item key="3" onClick={toggleFormSearch} style={{ color: 'blue' }}>
            <span>Recherche</span>
            <FaSearch />
          </Menu.Item> */}
          {/* {menuHautData &&
                  menuHautData
                    .sort((a, b) => (a.Tri < b.Tri ? -1 : 1))
                    .map((menu, i) => (
                      <StyledSubMenu id="menu"
                        key={`sub${i}`}
                        title={menu?.Lib}
                        // style={{ color: menu.type === 'type1' ? 'purple' : '#195aa0' }}
                        className={"niv1"}
                      >
                        {menu?.MenuBleu &&
                          menu?.MenuBleu.map((bleu, i) => (
                            <StyledSubMenu
                              key={bleu.id}
                              title={bleu?.Lib}
                              // style={{ color: bleu.type === 'type1' ? 'purple' : '#195aa0' }}
                              className={"niv2"}
                            >
                              {bleu?.MenuSous &&
                                bleu?.MenuSous.map((sous) => (
                                  <StyledMenuItem
                                    key={sous.id}
                                    // style={{ color: sous.type === 'type1' ? 'red' : '#195aa0' }}
                                    className={"niv3"}
                                  >
                                    <Link to={sous?.slug} onClick={onClose}>
                                      {sous?.Lib}
                                    </Link>
                                  </StyledMenuItem>
                                ))}
                            </StyledSubMenu>
                          ))}
                      </StyledSubMenu>
                    ))}
                     <StyledMenuItem className="plus" key="3">
                      <div><a href="/membre"> <FaLock style={{color: "#195aa0", marginRight:10}}/>Accès client / membre</a></div>
                      </StyledMenuItem>
                     <StyledMenuItem className="plus" key="2">
                    <div><a href="tel:33153430210"> <FaPhone style={{color: "red", marginRight:10}}/>01 53 43 02 10</a></div>
                  </StyledMenuItem>
                    <StyledMenuItem className="plus" key="1">
                    <div><TbMessage2Question style={{color: "#195aa0", marginRight:10}}/><a onClick={() => {
                    setFormQuestion(true);
                    setVisible(false);
                  }}>Une question</a></div>
                  </StyledMenuItem>
                  {formQuestion && ReactDOM.createPortal(
                    <PopUpContainer>
                      <PopUp className={["form",""]} gap={"0"}>
                          <PopUpTitle>Poser une question</PopUpTitle>
                            <Wrap>
                            <FormDemandeDeContactHP className="" onClick={() => setFormQuestion(false)}/>
                            </Wrap>
                        </PopUp>
                    </PopUpContainer>,
                    document.getElementById('root')
                  )} 
                 
                  <StyledMenuItem className="plus" key="3">
                    <div><FaRegFileAlt style={{color: "#195aa0", marginRight:10}}/><a>Recevoir une documentation</a></div>
                  </StyledMenuItem>
              </StyledMenu>
            </Sider>
            
          </StyledDrawer> */}
          <DrawerMenu dataMenuHaut={dataMenuHaut} visible={visible} onClose={onClose} showBtnDoc={showBtnDoc} />
        </>
      </StyledDiv>
    </div>
  );
});
export default NavBar;
